import React, { useState, useEffect, useCallback } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Slides from './components/Slides';
import PhotoChecklist from './components/PhotoChecklist';
import Camera from './components/Camera';
import PhotoReview from './components/PhotoReview';
import AnalisisGeneral from './components/AnalisisGeneral';
import ProcessImages from './components/ProcessImages';

function App() {
  const [photos, setPhotos] = useState(Array(11).fill(null));
  const [resultadosAnalisis, setResultadosAnalisis] = useState(null);
  const [fotosTomadas, setFotosTomadas] = useState(0);
  const totalFotosRequeridas = 11;
  const [hasAttemptedFullscreen, setHasAttemptedFullscreen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const resetAnalisis = useCallback(() => {
    setResultadosAnalisis(null);
    localStorage.removeItem('imagenesProcessadas');
  }, []);

  const resetInspeccion = useCallback(() => {
    setPhotos(Array(11).fill(null));
    setResultadosAnalisis(null);
    setFotosTomadas(0);
    localStorage.clear();
  }, []);

  // Función para entrar en modo de pantalla completa
  const enterFullscreen = useCallback(() => {
    const elem = document.documentElement;
    if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen().catch(err => console.log(err));
      } else if (elem.webkitRequestFullscreen) { // Safari
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { // IE11
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      }
    }
    setHasAttemptedFullscreen(true);
  }, []);

  // Función para guardar la foto tomada
  const handleSavePhoto = useCallback((photoTypeIndex, photoData) => {
    setPhotos(prevPhotos => {
      const newPhotos = [...prevPhotos];
      if (newPhotos[photoTypeIndex] === null) {
        setFotosTomadas(prev => prev + 1);
      }
      newPhotos[photoTypeIndex] = photoData;
      localStorage.setItem(`photo_${photoTypeIndex}`, photoData);
      return newPhotos;
    });
  }, []);

  // Recuperar las fotos almacenadas en localStorage al cargar la aplicación
  useEffect(() => {
    const storedPhotos = Array(11).fill(null).map((_, index) => {
      return localStorage.getItem(`photo_${index}`) || null;
    });
    setPhotos(storedPhotos);
  }, []);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    // Intentar entrar en modo de pantalla completa si es un dispositivo móvil
    const attemptFullscreen = () => {
      if (isMobile && !hasAttemptedFullscreen) {
        enterFullscreen();
      }
    };

    attemptFullscreen();

    // Manejar el cambio de visibilidad del documento
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        attemptFullscreen();
      }
    };

    // Manejar el redimensionamiento de la ventana
    const handleResize = () => {
      attemptFullscreen();
    };

    // Agregar y remover eventos relacionados con el intento de pantalla completa
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', attemptFullscreen);
    document.addEventListener('touchstart', attemptFullscreen);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', attemptFullscreen);
      document.removeEventListener('touchstart', attemptFullscreen);
    };
  }, [enterFullscreen, hasAttemptedFullscreen]);

  useEffect(() => {
    // Manejar el cambio de estado de pantalla completa
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement || !!document.webkitFullscreenElement || !!document.mozFullScreenElement || !!document.msFullscreenElement);
    };

    // Agregar y remover eventos relacionados con el cambio de pantalla completa
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return (
    <Router>
      <div className="App" onClick={enterFullscreen}>
        <Switch>
          <Route path="/" exact component={Slides} />
          <Route path="/checklist" render={(props) => (
            <PhotoChecklist 
              {...props} 
              photos={photos} 
              onSavePhoto={handleSavePhoto}
            />
          )} />
          <Route path="/camera/:photoType" render={(props) => (
            <Camera 
              {...props} 
              onSavePhoto={handleSavePhoto} 
              fotosTomadas={fotosTomadas}
              totalFotosRequeridas={totalFotosRequeridas}
              resetAnalisis={resetAnalisis}
            />
          )} />
          <Route path="/review" component={PhotoReview} />
          <Route path="/process-images" render={(props) => (
            <ProcessImages 
              {...props} 
              setResultadosAnalisis={setResultadosAnalisis}
              totalFotosRequeridas={totalFotosRequeridas}
            />
          )} />
          <Route path="/analisis-general" render={(props) => (
            <AnalisisGeneral 
              {...props} 
              resultados={resultadosAnalisis} 
              onResetAnalisis={resetAnalisis}
              onResetInspeccion={resetInspeccion}
            />
          )} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;