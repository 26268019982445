import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import logoColorPng from '../images/logo192.png';

const InformePDF = ({ resultados }) => {
  const generarInforme = () => {
    if (!resultados) {
      console.error('No hay resultados disponibles para generar el informe');
      return;
    }

    const doc = new jsPDF();
    
    // Configuración de estilos
    const colorPrimario = '#261d56';
    const colorSecundario = '#4a4a4a';
    const colorFondo = '#f5f5f5';
    const fontPrimaria = 'helvetica';
    const fontSecundaria = 'helvetica';

    const styles = {
      header: {
        fontSize: 16,
        fontStyle: 'bold',
        textColor: colorPrimario
      },
      sectionTitle: {
        fontSize: 14,
        fontStyle: 'bold',
        textColor: colorPrimario
      },
      normal: {
        fontSize: 10,
        fontStyle: 'normal',
        textColor: colorSecundario
      },
      small: {
        fontSize: 9,
        fontStyle: 'normal',
        textColor: colorSecundario
      }
    };

    const applyStyle = (doc, style) => {
      doc.setFont(fontPrimaria, style.fontStyle);
      doc.setFontSize(style.fontSize);
      doc.setTextColor(style.textColor);
    };

    let yPos = 30;

    const addHeader = () => {
      doc.setFillColor(colorFondo);
      doc.rect(0, 0, 210, 297, 'F');
      doc.rect(0, 0, 210, 25, 'F');
      doc.addImage(logoColorPng, 'PNG', 10, 5, 15, 15);
      applyStyle(doc, styles.header);
      doc.text('Informe de Inspección Vehicular', 30, 15);
    };

    const addFooter = () => {
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        applyStyle(doc, styles.small);
        doc.text(`Página ${i} de ${totalPages}`, 195, 287, { align: 'right' });
        doc.text(`Generado el ${new Date().toLocaleDateString()}`, 15, 287);
      }
    };

    const addSection = (title, content) => {
      applyStyle(doc, styles.sectionTitle);
      doc.text(title, 15, yPos);
      yPos += 7;
      
      doc.setLineWidth(0.5);
      doc.line(15, yPos, 195, yPos);
      yPos += 5;
      
      applyStyle(doc, styles.normal);
      content();
      
      yPos += 10;
    };

    const checkPageBreak = (height) => {
      if (yPos + height > 280) {
        doc.addPage();
        addHeader();
        yPos = 30;
      }
    };

    const addImage = (imagen, width, height) => {
      checkPageBreak(height + 5);
      doc.addImage(imagen, 'JPEG', 15, yPos, width, height);
      yPos += height + 5;
    };

    addHeader();

    // Información General
    addSection('Información General', () => {
      doc.autoTable({
        startY: yPos,
        head: [['Campo', 'Valor']],
        body: [
          ['Fecha de Inspección', new Date().toLocaleDateString()],
          ['Inspector', 'Nombre del Inspector'],
          ['ID de Inspección', '#' + Math.random().toString(36).substr(2, 9).toUpperCase()]
        ],
        theme: 'grid',
        headStyles: { 
          fillColor: [38, 29, 86], 
          textColor: [255, 255, 255],
          fontSize: styles.normal.fontSize,
          fontStyle: 'bold'
        },
        bodyStyles: { 
          textColor: styles.normal.textColor,
          fontSize: styles.normal.fontSize
        },
        alternateRowStyles: { fillColor: [245, 245, 245] },
        margin: { left: 15, right: 15 }
      });
      yPos = doc.lastAutoTable.finalY + 10;
    });

    // Sección de Documento
    addSection('Documento', () => {
      if (resultados.documento && resultados.documento.imagen) {
        addImage(resultados.documento.imagen, 80, 60);
        if (resultados.documento.datos) {
          const data = [];
          
          // Datos del Propietario
          if (resultados.documento.datos.Propietario) {
            data.push(['Propietario', '']);
            Object.entries(resultados.documento.datos.Propietario).forEach(([key, value]) => {
              data.push([`  ${key}`, value]);
            });
          }
          
          // Datos del Vehículo
          if (resultados.documento.datos.Vehiculo) {
            data.push(['Vehículo', '']);
            Object.entries(resultados.documento.datos.Vehiculo).forEach(([key, value]) => {
              data.push([`  ${key}`, value]);
            });
          }
          
          // Otros datos que no estén en Propietario o Vehículo
          Object.entries(resultados.documento.datos).forEach(([key, value]) => {
            if (key !== 'Propietario' && key !== 'Vehiculo') {
              if (typeof value === 'object') {
                data.push([key, '']);
                Object.entries(value).forEach(([subKey, subValue]) => {
                  data.push([`  ${subKey}`, subValue]);
                });
              } else {
                data.push([key, value]);
              }
            }
          });
  
          doc.autoTable({
            startY: yPos,
            head: [['Campo', 'Valor']],
            body: data,
            theme: 'striped',
            headStyles: { 
              fillColor: [38, 29, 86], 
              textColor: [255, 255, 255],
              fontSize: styles.normal.fontSize,
              fontStyle: 'bold'
            },
            bodyStyles: { 
              textColor: styles.normal.textColor,
              fontSize: styles.normal.fontSize
            },
            alternateRowStyles: { fillColor: [240, 240, 240] },
            margin: { left: 15, right: 15 },
            columnStyles: {
              0: { cellWidth: 'auto' },
              1: { cellWidth: 'auto' }
            },
            styles: { overflow: 'linebreak', cellPadding: 2 },
            bodyStyles: { valign: 'top' }
          });
          yPos = doc.lastAutoTable.finalY + 10;
        } else {
          applyStyle(doc, styles.normal);
          doc.text('No se pudieron extraer datos del documento.', 15, yPos);
          yPos += 10;
        }
      } else {
        applyStyle(doc, styles.normal);
        doc.text('No se encontró imagen del documento.', 15, yPos);
        yPos += 10;
      }
    });

    // Sección de Odómetro
    addSection('Odómetro', () => {
      if (resultados.odometro && resultados.odometro.imagenRecortada) {
        addImage(resultados.odometro.imagenRecortada, 80, 60);
        const lectura = resultados.odometro.datos ? resultados.odometro.datos.extracted_text : 'No disponible';
        applyStyle(doc, styles.normal);
        doc.text(`Lectura: ${lectura}`, 15, yPos);
        yPos += 10;
      } else {
        applyStyle(doc, styles.normal);
        doc.text('No se encontró imagen del odómetro.', 15, yPos);
        yPos += 10;
      }
    });

    // Sección de Patentes
    addSection('Patentes Detectadas', () => {
      if (resultados.patentes && resultados.patentes.length > 0) {
        resultados.patentes.forEach((patente, index) => {
          if (patente.imagenRecortada) {
            addImage(patente.imagenRecortada, 80, 40);
            const patenteExtraida = patente.datos ? patente.datos.extracted_text : 'No legible';
            applyStyle(doc, styles.normal);
            doc.text(`Patente ${index + 1}: ${patenteExtraida}`, 15, yPos);
            yPos += 10;
          }
        });
      } else {
        applyStyle(doc, styles.normal);
        doc.text('No se detectaron patentes.', 15, yPos);
        yPos += 10;
      }
    });

    // Sección de Chasis
    addSection('Chasis Detectado', () => {
      if (resultados.chasis && resultados.chasis.imagenRecortada) {
        addImage(resultados.chasis.imagenRecortada, 80, 40);
        const chasisExtraido = resultados.chasis.datos ? resultados.chasis.datos.extracted_text : 'No legible';
        applyStyle(doc, styles.normal);
        doc.text(`Chasis: ${chasisExtraido}`, 15, yPos);
        yPos += 10;
      } else {
        applyStyle(doc, styles.normal);
        doc.text('No se detectó el Chasis.', 15, yPos);
        yPos += 10;
      }
    });

  // Sección de Daños
  addSection('Daños Detectados', () => {
    if (resultados.danos && Array.isArray(resultados.danos) && resultados.danos.length > 0) {
      let danosData = [];
      resultados.danos.forEach((dano, index) => {
        if (dano && typeof dano === 'object' && Array.isArray(dano.daños)) {
          dano.daños.forEach((d, i) => {
            if (d && typeof d === 'object' && d.imagenRecortada) {
              danosData.push([
                `Daño ${index + 1}-${i + 1}`, 
                d.class, // La clase traducida
                `${(d.confidence * 100).toFixed(2)}%`,
                d.imagenRecortada
              ]);
            }
          });
        }
      });

      if (danosData.length > 0) {
        const imageWidth = 40;
        const imageHeight = 30;
        const imagesPerRow = 3;
        const rowHeight = imageHeight + 20;

        for (let i = 0; i < danosData.length; i += imagesPerRow) {
          checkPageBreak(rowHeight);

          for (let j = 0; j < imagesPerRow && i + j < danosData.length; j++) {
            const [id, tipo, confianza, imagen] = danosData[i + j];
            const x = 15 + j * (imageWidth + 15);
            
            doc.addImage(imagen, 'JPEG', x, yPos, imageWidth, imageHeight);
            applyStyle(doc, styles.small);
            doc.text(id, x, yPos + imageHeight + 5, { maxWidth: imageWidth });
            doc.text(tipo, x, yPos + imageHeight + 10, { maxWidth: imageWidth });
          }

          yPos += rowHeight;
        }
      } else {
        applyStyle(doc, styles.normal);
        doc.text('No se encontraron imágenes de daños para mostrar.', 15, yPos);
        yPos += 10;
      }
    } else {
      applyStyle(doc, styles.normal);
      doc.text('No se detectaron daños.', 15, yPos);
      yPos += 10;
    }
  });

    console.log('Datos del documento:', resultados.documento?.datos?.Vehiculo);
    console.log('Patentes detectadas:', resultados.patentes);
    console.log('Chasis detectado:', resultados.chasis);

// Sección de Comparación de Datos
addSection('Comparación de Datos', () => {
  const comparaciones = [];

  // Extraer datos del documento
  const datosDocumento = resultados.documento?.datos || {};
  const vehiculo = datosDocumento.vehiculo || {};
  const patenteDocumento = vehiculo.Patente || 'No disponible';
  const chasisDocumento = vehiculo.Numero_chasis || 'No disponible';

  console.log('Datos extraídos del documento:', { patenteDocumento, chasisDocumento });

  // Comparar patentes
  let patentesFrontalesDetectadas = [];
  let patentesTraserasDetectadas = [];
  if (resultados.patentes && resultados.patentes.length > 0) {
    resultados.patentes.forEach((patente, index) => {
      const patenteDetectada = patente.datos ? patente.datos.extracted_text : 'No legible';
      if (index === 0) {
        patentesFrontalesDetectadas.push(patenteDetectada);
      } else {
        patentesTraserasDetectadas.push(patenteDetectada);
      }
    });
  }

  const patentesFrontalesStr = patentesFrontalesDetectadas.join(', ') || 'No detectada';
  const patentesTraserasStr = patentesTraserasDetectadas.join(', ') || 'No detectada';

  let resultadoComparacionPatente = 'No se pudo comparar';
  if (patenteDocumento !== 'No disponible') {
    if (patentesFrontalesDetectadas.length > 0 || patentesTraserasDetectadas.length > 0) {
      const todasLasPatentesDetectadas = [...patentesFrontalesDetectadas, ...patentesTraserasDetectadas];
      const coincide = todasLasPatentesDetectadas.some(patente => 
        patente.replace(/\s/g, '').toUpperCase() === patenteDocumento.replace(/\s/g, '').toUpperCase()
      );
      resultadoComparacionPatente = coincide ? 'Coincide' : 'No coincide';
    }
  }

  comparaciones.push(['Patentes', patenteDocumento, `Frontal: ${patentesFrontalesStr}\nTrasera: ${patentesTraserasStr}`, resultadoComparacionPatente]);

  // Comparar chasis
  const chasisDetectado = resultados.chasis && resultados.chasis.datos ? resultados.chasis.datos.extracted_text : 'No detectado';
  let resultadoComparacionChasis = 'No se pudo comparar';
  if (chasisDocumento !== 'No disponible' && chasisDetectado !== 'No detectado') {
    const coincideChasis = chasisDetectado.replace(/\s/g, '').toUpperCase() === chasisDocumento.replace(/\s/g, '').toUpperCase();
    resultadoComparacionChasis = coincideChasis ? 'Coincide' : 'No coincide';
  }
  comparaciones.push(['Chasis', chasisDocumento, chasisDetectado, resultadoComparacionChasis]);

  if (comparaciones.length > 0) {
    doc.autoTable({
      startY: yPos,
      head: [['Campo', 'Dato del Documento', 'Dato Detectado', 'Resultado']],
      body: comparaciones,
      theme: 'striped',
      headStyles: { 
        fillColor: [38, 29, 86],
        textColor: [255, 255, 255],
        fontSize: styles.normal.fontSize,
        fontStyle: 'bold'
      },
      bodyStyles: { 
        textColor: styles.normal.textColor,
        fontSize: styles.normal.fontSize
      },
      alternateRowStyles: { fillColor: [240, 240, 240] },
      margin: { left: 15, right: 15 },
      styles: { fontSize: 10, cellPadding: 5 },
      columnStyles: {
        2: { cellWidth: 'auto', whiteSpace: 'pre-wrap' }
      }
    });
    yPos = doc.lastAutoTable.finalY + 10;
  } else {
    applyStyle(doc, styles.normal);
    doc.text('No hay datos suficientes para realizar comparaciones.', 15, yPos);
    yPos += 10;
  }

  // logs para depuración
  console.log('Datos completos del documento:', datosDocumento);
  console.log('Datos del vehículo:', vehiculo);
  console.log('Patente del documento:', patenteDocumento);
  console.log('Chasis del documento:', chasisDocumento);
});
    
    // logs para depuración
    console.log('Datos completos del documento:', resultados.documento?.datos);
    console.log('Detalle de patentes detectadas:', resultados.patentes?.map(p => ({
      texto: p.datos ? p.datos.extracted_text : 'No legible',
      confianza: p.confidence,
      indice: p.indice
    })));
    console.log('Detalle del chasis detectado:', resultados.chasis);

    // Sección de Todas las Fotos
    addSection('Fotos Capturadas', () => {
      const todasLasFotos = [
        { nombre: 'Frontal', imagen: localStorage.getItem('photo_0') },
        { nombre: 'Trasera', imagen: localStorage.getItem('photo_1') },
        { nombre: 'Lateral Izquierda', imagen: localStorage.getItem('photo_2') },
        { nombre: 'Lateral Derecha', imagen: localStorage.getItem('photo_3') },
        { nombre: 'Motor', imagen: localStorage.getItem('photo_4') },
        { nombre: 'Volante', imagen: localStorage.getItem('photo_5') },
        { nombre: 'Panel', imagen: localStorage.getItem('photo_6') },
        { nombre: 'Odómetro', imagen: localStorage.getItem('photo_7') },
        { nombre: 'Rueda de repuesto', imagen: localStorage.getItem('photo_8') },
        { nombre: 'Chasis', imagen: localStorage.getItem('photo_9') },
        { nombre: 'Documento', imagen: localStorage.getItem('photo_10') }
      ];
    
      const imageWidth = 80;
      const imageHeight = 60;
      const imagesPerRow = 2;
      const rowHeight = imageHeight + 20;
      const margin = 15;
    
      todasLasFotos.forEach((foto, index) => {
        if (foto.imagen) {
          if (index % imagesPerRow === 0) {
            checkPageBreak(rowHeight);
          }
    
          const x = margin + (index % imagesPerRow) * (imageWidth + margin);
          
          doc.addImage(foto.imagen, 'JPEG', x, yPos, imageWidth, imageHeight);
          
          // Agregar un rectángulo alrededor de la imagen
          doc.setDrawColor(200);
          doc.setLineWidth(0.5);
          doc.rect(x, yPos, imageWidth, imageHeight);
    
          // Agregar el nombre de la foto
          applyStyle(doc, styles.small);
          doc.text(foto.nombre, x + imageWidth / 2, yPos + imageHeight + 5, { align: 'center', maxWidth: imageWidth });
    
          if (index % imagesPerRow === imagesPerRow - 1 || index === todasLasFotos.length - 1) {
            yPos += rowHeight;
          }
        }
      });
    });

    addFooter();
    doc.save('informe-inspeccion-vehicular.pdf');
  };

  return { generarInforme };
};

export default InformePDF;