import { useState, useEffect, useCallback } from 'react';
import * as tf from '@tensorflow/tfjs';

const ObjectDetector = ({ videoRef, modelPath, onDetection, targetClass }) => {
  const [model, setModel] = useState(null);

  useEffect(() => {
    const loadModel = async () => {
      console.log('Cargando modelo...', modelPath);
      try {
        const net = await tf.loadGraphModel(modelPath);
        setModel(net);
        console.log('Modelo cargado exitosamente');
      } catch (error) {
        console.error('Error al cargar el modelo:', error);
      }
    };
    loadModel();
  }, [modelPath]);

  const performObjectDetection = useCallback(async () => {
    if (!model || !videoRef.current) return null;
  
    const video = videoRef.current;
    
    if (video.readyState !== 4) return null;

    try {
      const img = tf.browser.fromPixels(video);
      const resized = tf.image.resizeBilinear(img, [320, 320]);
      const casted = resized.cast('int32');
      const expanded = casted.expandDims(0);
    
      const obj = await model.executeAsync(expanded);
      
      let scores, classes;
      if (Array.isArray(obj)) {
        // la parte del modelo panel
        /*
        if (modelPath.includes('panel')) {
          scores = await obj[4].array();
          classes = await obj[5].array();
          console.log("Panel/Odómetro - Scores raw:", scores);
          console.log("Panel/Odómetro - Classes raw:", classes);
          
          // Procesar los resultados
          scores = scores[0];
          classes = classes[0];
          
          // Filtrar resultados con un umbral mínimo
          const threshold = 0.1;
          const filteredResults = scores.map((score, index) => ({score, class: classes[index]}))
                                        .filter(item => item.score > threshold)
                                        .sort((a, b) => b.score - a.score);
          
          console.log("Panel/Odómetro - Resultados filtrados:", filteredResults);
          
          scores = filteredResults.map(item => item.score);
          classes = filteredResults.map(item => item.class);
        } else
        */
        if (modelPath.includes('volante')) {
          scores = await obj[0].array();
          classes = await obj[2].array();
        } else if (modelPath.includes('vin')) {
          scores = await obj[7].array();
          classes = await obj[0].array();
        } else {
          scores = await obj[1].array();
          classes = await obj[3].array();
        }
      } else if (obj instanceof tf.Tensor) {
        const data = await obj.array();
        scores = [data[0].slice(0, 100)];
        classes = [data[0].slice(100)];
      }

      // Asegurarse de que scores y classes sean arrays unidimensionales
      scores = scores.flat();
      classes = classes.flat();

      console.log('Resultados finales - Scores:', scores);
      console.log('Resultados finales - Classes:', classes);

      tf.dispose([img, resized, casted, expanded, ...obj]);
    
      return { scores, classes };
    } catch (error) {
      console.error('Error al ejecutar el modelo:', error);
      return null;
    }
  }, [model, videoRef, modelPath]);

  useEffect(() => {
    if (!model || !videoRef.current) return;

    let animationFrameId;
    let isDetecting = true;

    const detectFrame = async () => {
      if (!isDetecting) return;

      const detectionResults = await performObjectDetection();
      if (detectionResults) {
        const { scores, classes } = detectionResults;
        console.log('Resultados de detección:', { scores, classes });
        onDetection(scores, classes.map(c => c.toString()));
      }
      animationFrameId = requestAnimationFrame(detectFrame);
    };

    const checkVideoReady = () => {
      if (videoRef.current.readyState === 4) {
        detectFrame();
      } else {
        setTimeout(checkVideoReady, 100);
      }
    };

    checkVideoReady();

    return () => {
      isDetecting = false;
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [model, videoRef, performObjectDetection, onDetection]);

  return null;
};

export default ObjectDetector;