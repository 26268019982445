import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Box, Modal, IconButton, Typography, Fade } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Image } from 'image-js';
import ObjectDetector from './ObjectDetector';
import Speedometer from './Speedometer';
import '../styles/Camera.css';
import frenteSilueta from '../images/frente.svg';
import perfilIzquierdoSilueta from '../images/perfilizquierdo.svg';
import perfilDerechoSilueta from '../images/perfilderecho.svg';
import traseroSilueta from '../images/trasero.svg';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

// Definir los umbrales de detección para cada modelo
const DETECTION_THRESHOLDS = {
  angulos: {
    frente: 0.995,
    trasero: 0.995,
    perfil: 0.995
  },
  vin: 0.80
};

const getTimerColor = (timeLeft) => {
  if (timeLeft > 20) return '#4CAF50'; 
  if (timeLeft > 10) return '#FFC107'; 
  return '#F44336';
};

const getSiluetaColor = (score) => {
  if (score === null) return '#F44336';
  if (score < 0.4) return '#F44336';
  if (score < 0.7) return '#FFC107';
  return '#4CAF50';
};

const Camera = ({ onSavePhoto, fotosTomadas, totalFotosRequeridas, resetAnalisis, ...props }) => {
  const { photoType } = useParams();
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [timerOpen, setTimerOpen] = useState(false);
  const [qualityMessages, setQualityMessages] = useState([]);
  const history = useHistory();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(30);
  const timerInterval = useRef(null);
  const [isFirstAttempt, setIsFirstAttempt] = useState(true);
  const [detectionScore, setDetectionScore] = useState(null);
  const [detectionClass, setDetectionClass] = useState(null);
  const [processingPhoto, setProcessingPhoto] = useState(false);
  const processingTimeout = useRef(null);
  const [showDetector, setShowDetector] = useState(true);
  const [showAIMessage, setShowAIMessage] = useState(true);
  const [inspeccionCompletaModalOpen, setInspeccionCompletaModalOpen] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [captureMessage, setCaptureMessage] = useState('');

  useEffect(() => {
    resetAnalisis();
  }, [resetAnalisis]);
  
  // Configuración de la foto actual basada en el tipo de foto
  const photoConfig = useMemo(() => {
    const config = {
      '0': { model: 'angulos', class: 'Frente', silueta: frenteSilueta, classNumber: 1 },
      '1': { model: 'angulos', class: 'Trasero', silueta: traseroSilueta, classNumber: 3 },
      '2': { model: 'angulos', class: 'Perfil', silueta: perfilIzquierdoSilueta, classNumber: 2 },
      '3': { model: 'angulos', class: 'Perfil', silueta: perfilDerechoSilueta, classNumber: 2 },
      '4': { model: null, class: null },
      '5': { model: null, class: null }, // Cambiado para captura manual
      '6': { model: 'panel', class: 'Panel', classNumber: 1 },
      '7': { model: 'panel', class: 'Odómetro', classNumber: 2 },
      '8': { model: null, class: null },
      '9': { model: 'vin', class: 'VIN', classNumber: 1 },
      '10': { model: null, class: null },
    };
    return config[photoType.toString()] || { model: null, class: null };
  }, [photoType]);

  // Determinar si se debe utilizar la detección
  const useDetection = useMemo(() => {
    return photoConfig.model !== null && 
           photoConfig.model !== 'panel' && 
           isFirstAttempt && 
           showDetector;
  }, [photoConfig, isFirstAttempt, showDetector]);

  // Función para calcular la nitidez de la imagen
  const calculateBlur = useCallback((greyImage) => {
    const sobel = greyImage.sobelFilter();
    const data = sobel.data;
    const mean = data.reduce((acc, val) => acc + val, 0) / data.length;
    const standardDeviation = Math.sqrt(data.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / data.length);
    return standardDeviation;
  }, []);

  // Función para analizar la calidad de la imagen
  const analyzeImageQuality = useCallback(async (imageData) => {
    const img = await Image.load(imageData);
    const greyImage = img.grey();
    const brightness = greyImage.mean;
    const blur = calculateBlur(greyImage);
    let messages = [];
    if (brightness > 150) {
      messages.push('La imagen está demasiado brillante.');
    }
    if (blur < 63) {
      messages.push('La imagen está borrosa.');
    }
    setQualityMessages(messages);
  }, [calculateBlur]);

  // Función para detener la cámara
  const stopCamera = useCallback(() => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
  }, []);

  // Función para iniciar el temporizador
  const startTimer = useCallback(() => {
    if (!timerStarted) {
      setTimerStarted(true);
      setTimeLeft(30);
      timerInterval.current = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timerInterval.current);
            setTimerOpen(true);
            setShowDetector(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  }, [timerStarted]);

  // Función para iniciar la cámara
  const startCamera = useCallback(async () => {
    const constraints = {
      video: {
        facingMode: "environment",
        width: { ideal: 1920 },
        height: { ideal: 1080 }
      }
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        console.log('Cámara iniciada con resolución alta');
      }
    } catch (err) {
      console.error('Error accessing media devices.', err);
    }
  }, []);

  // Función para capturar una foto
  const handleCapture = useCallback(async (isAuto = false) => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      const imageData = canvasRef.current.toDataURL('image/jpeg');
      setImage(imageData);
      onSavePhoto(parseInt(photoType, 10), imageData);
          // Analizar la calidad de la imagen
      const quality = await analyzeImageQuality(imageData);
      clearInterval(timerInterval.current);
      stopCamera();
      resetAnalisis();
      setOpen(true);
      analyzeImageQuality(imageData);
      setShowDetector(false);
      
      console.log(isAuto ? 'Captura automática realizada' : 'Captura manual realizada');
    }
  }, [onSavePhoto, photoType, analyzeImageQuality, stopCamera, resetAnalisis]);

    // Función para manejar la detección de objetos
    const handleDetection = useCallback((scores, detectedClasses) => {
      if (photoConfig.model && !processingPhoto && !timerOpen && showDetector) {
        console.log("Detección recibida:", scores, detectedClasses);
        
        let targetIndex = -1;
        let highestScore = -1;
        
        for (let i = 0; i < detectedClasses.length; i++) {
          const currentClass = parseInt(detectedClasses[i]);
          if (scores[i] > highestScore && currentClass === photoConfig.classNumber) {
            highestScore = scores[i];
            targetIndex = i;
          }
        }
    
        if (targetIndex !== -1) {
          const score = scores[targetIndex];
          console.log(`Detección: ${score}`);
          setDetectionScore(score);
          
          let threshold = DETECTION_THRESHOLDS[photoConfig.model];
          if (photoConfig.model === 'angulos') {
            threshold = DETECTION_THRESHOLDS.angulos[photoConfig.class.toLowerCase()];
          }
    
          if (score > threshold) {
            setProcessingPhoto(true);
            setCaptureMessage('¡Quédese ahí unos segundos, capturando foto...');
            
            clearTimeout(processingTimeout.current);
            processingTimeout.current = setTimeout(() => {
              console.log(`Detección exitosa con confianza de ${score.toFixed(2)}`);
              handleCapture(true);
              setCaptureMessage('');
            }, 2000);
          }
        } else {
          setDetectionScore(null);
          setCaptureMessage('');
        }
      }
    }, [photoConfig, handleCapture, processingPhoto, timerOpen, showDetector]);

    // Función para manejar la recaptura de la foto
    const handleRetake = useCallback(() => {
      setImage(null);
      setOpen(false);
      setTimerOpen(false);
      setIsFirstAttempt(false);
      setProcessingPhoto(false);
      setDetectionScore(null);
      setDetectionClass(null);
      setShowDetector(false);
      setTimeLeft(0); // Reinicia el temporizador
      setTimerStarted(false); // Reinicia el estado del temporizador
      stopCamera();
      startCamera();
    }, [startCamera, stopCamera]);

  // Función para manejar la confirmación de la foto
  const handleConfirm = useCallback(() => {
    setOpen(false);
    onSavePhoto(parseInt(photoType, 10), image);
    
    const nuevasFotosTomadas = fotosTomadas + 1;
    if (nuevasFotosTomadas === totalFotosRequeridas + 1) {
      setInspeccionCompletaModalOpen(true);
    } else {
      history.push('/checklist');
    }
  }, [onSavePhoto, photoType, image, history, fotosTomadas, totalFotosRequeridas]);

  // Función para manejar la confirmación de la inspección completa
  const handleInspeccionCompletaConfirm = useCallback(() => {
    setInspeccionCompletaModalOpen(false);
    history.push('/process-images');
  }, [history]);

  // Función para manejar la captura manual
  const handleManualCapture = useCallback(() => {
    setTimerOpen(false);
    setIsFirstAttempt(false);
    setProcessingPhoto(false);
    setDetectionScore(null);
    setDetectionClass(null);
    setShowDetector(false);
    startCamera();
  }, [startCamera]);

  // Función para obtener el color de la animación basado en el estado de detección
  const getDetectionColor = (score) => {
    if (score === null) return '#F44336'; // Rojo por defecto
    if (score < 0.4) {
      return `rgb(255, ${Math.round((score / 0.4) * 165)}, 0)`;
    } else if (score < 0.7) {
      return `rgb(255, ${Math.round(165 + ((score - 0.4) / 0.3) * 90)}, 0)`;
    } else {
      return `rgb(${Math.round(255 - ((score - 0.7) / 0.3) * 255)}, 255, 0)`;
    }
  };

  // Función para obtener el mensaje basado en el estado de detección
  const getDetectionMessage = (score) => {
    if (score === null) return 'Buscando objeto...';
    if (score < 0.4) return 'No se detecta nada';
    if (score < 0.7) return 'Ubique el objeto en el centro';
    if (score < 0.9) return 'Casi listo, centre un poco más';
    return '¡Perfecto! Acérquese un poco más...';
  };

  // Efecto para manejar la orientación del dispositivo
  useEffect(() => {
    const handleOrientation = () => {
      const newIsPortrait = window.orientation === 0 || window.orientation === 180;
      setIsPortrait(newIsPortrait);
      if (!newIsPortrait && !timerStarted) {
        startTimer();
      }
    };
  
    window.addEventListener('orientationchange', handleOrientation);
    handleOrientation(); // Comprueba la orientación inicial
  
    return () => {
      window.removeEventListener('orientationchange', handleOrientation);
    };
  }, [timerStarted, startTimer]);

  // Efecto para iniciar y detener la cámara
  useEffect(() => {
    let isMounted = true;
    
    const initCamera = async () => {
      await startCamera();
      if (isMounted && videoRef.current) {
        videoRef.current.onloadedmetadata = () => {
          if (isMounted && videoRef.current) {
            videoRef.current.play();
          }
        };
      }
    };

    initCamera();

    return () => {
      isMounted = false;
      stopCamera();
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }
      if (processingTimeout.current) {
        clearTimeout(processingTimeout.current);
      }
    };
  }, [startCamera, stopCamera]);

  // Efecto para mostrar el mensaje de IA durante 5 segundos
  useEffect(() => {
    if (useDetection) {
      setShowAIMessage(true);
      const timer = setTimeout(() => {
        setShowAIMessage(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [useDetection]);

  // Función para manejar el retroceso
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container className="container" style={{ padding: 0, height: '100vh', position: 'relative' }}>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" style={{ height: '100%' }}>
      <IconButton
        onClick={handleGoBack}
        className="back-button"
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          zIndex: 1001,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
        }}
      >
        <ArrowBackIcon />
      </IconButton>
        <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}></video>
        {useDetection && (
          <div className="detection-area" style={{ 
            border: '4px solid #FFFFFF', // Mantener el borde blanco fijo
            transition: 'none'
          }}>
            {photoConfig.silueta && (
              <img 
                src={photoConfig.silueta} 
                alt="Silueta" 
                className="vehicle-silhouette" 
                style={{ 
                  filter: `drop-shadow(0 0 5px ${getSiluetaColor(detectionScore)})`, // Aplicar el color dinámico aquí
                  transition: 'filter 0.3s ease' // Agregar una transición suave para el cambio de color
                }}
              />
            )}
          </div>
        )}
        {captureMessage && (
          <Box 
            className="capture-message" 
            style={{ 
              position: 'absolute',
              top: '50%',  // Posicionar en el centro vertical
              left: '50%', // Posicionar en el centro horizontal
              transform: 'translate(-50%, 150px)',  // Centrar y movemos hacia abajo
              zIndex: 1001,
              width: '100%',  // Ocupar todo el ancho disponible
              textAlign: 'center',  // Centrar el texto
            }}
          >
            <Typography 
              variant="body1" 
              style={{ 
                color: 'white', 
                backgroundColor: 'rgba(76, 175, 80, 0.8)', 
                padding: '10px 20px', 
                borderRadius: '20px',
                fontWeight: 'bold',
                display: 'inline-block',  // Para que el fondo se ajuste al contenido
                maxWidth: '80%',  // Limitar el ancho máximo
              }}
            >
              {captureMessage}
            </Typography>
          </Box>
        )}
        {useDetection && (
          <Box className="speedometer-container" style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000 }}>
          <Speedometer value={detectionScore * 100 || 0} />
        </Box>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480"></canvas>
        {useDetection && !processingPhoto && (
          <ObjectDetector
            videoRef={videoRef}
            modelPath={`/modelo/${photoConfig.model}/modelTfjs/model.json`}
            onDetection={handleDetection}
            targetClass={photoConfig.class}
          />
        )}
        {useDetection && !processingPhoto && (
          <Box 
            className="detection-info" 
            style={{ 
              position: 'absolute', 
              bottom: 20, 
              left: 20,
              zIndex: 1000,
              maxWidth: '70%',
            }}
          >
            <Typography 
              variant="body1" 
              style={{ 
                color: 'black',  // Color de las letras negro
                backgroundColor: getDetectionColor(detectionScore),  // Fondo con el color de detección
                padding: '8px 16px', 
                borderRadius: '20px',
                fontWeight: 'bold',
                display: 'inline-block',
              }}
            >
              {getDetectionMessage(detectionScore)}
            </Typography>
          </Box>
        )}
        {(!isFirstAttempt || !useDetection || photoConfig.model === 'panel' || photoConfig.model === null) && !processingPhoto ? (
          <IconButton
            onClick={() => handleCapture(false)}
            className="capture-button"
          >
            <CameraAltIcon style={{ fontSize: '2rem' }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={handleGoBack}
            className="back-button"
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              zIndex: 1001,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      {!isPortrait && timeLeft > 0 && !processingPhoto && (
        <Box className="timer-container">
          <div className="timer">
            <div 
              className="timer-fill" 
              style={{ 
                height: `${(timeLeft / 30) * 100}%`,
                backgroundColor: getTimerColor(timeLeft)
              }}
            ></div>
            <span className="timer-text">{timeLeft}</span>
          </div>
        </Box>
      )}
      {isPortrait && (
        <Box
          className="rotation-animation"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2000,
          }}
        >
          <ScreenRotationIcon style={{ fontSize: 60, color: 'white', animation: 'rotate 2s infinite linear' }} />
          <Typography variant="h6" style={{ color: 'white', marginTop: 20 }}>
            Gira tu dispositivo horizontalmente
          </Typography>
        </Box>
      )}
        {useDetection && showAIMessage && !isPortrait && photoConfig.model !== 'panel' && photoConfig.model !== null && (
          <Fade in={showAIMessage}>
            <Box className="ai-message">
              <Typography variant="body2">
                Esta captura de foto utiliza detección automática con inteligencia artificial (función experimental).
              </Typography>
            </Box>
          </Fade>
        )}
        <Modal open={timerOpen} onClose={handleManualCapture}>
          <Box className="modal-content">
            <Typography variant="h6" style={{ color: 'black' }}>Tiempo agotado</Typography>
            <Typography variant="body1" style={{ marginTop: '10px', marginBottom: '20px', color: 'black' }}>
              No se pudo detectar automáticamente. ¿Deseas intentar una captura manual?
            </Typography>
            <Button variant="contained" onClick={handleManualCapture} className="primary-button">
              Captura Manual
            </Button>
          </Box>
        </Modal>
        <Modal open={inspeccionCompletaModalOpen} onClose={() => setInspeccionCompletaModalOpen(false)}>
        <Box className="modal-content">
          <Typography variant="h6" style={{ color: 'black' }}>Inspección Completa</Typography>
          <Typography variant="body1" style={{ marginTop: '10px', marginBottom: '20px', color: 'black' }}>
            Has completado todas las fotografías requeridas. ¿Deseas continuar con el análisis?
          </Typography>
          <Button variant="contained" onClick={handleInspeccionCompletaConfirm} className="primary-button">
            Continuar al Análisis
          </Button>
        </Box>
      </Modal>
      <Modal 
        open={open} 
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleRetake();
          }
        }}
        disableEscapeKeyDown
      >
        <Box className="modal-content">
          <img src={image} alt="Captured" className="captured-image" />
          {qualityMessages.map((message, index) => (
            <Box key={index} display="flex" alignItems="center" color="red" mt={1}>
              <CloseIcon />
              <Typography variant="body1" style={{ marginLeft: '10px', color: 'black' }}>{message}</Typography>
            </Box>
          ))}
          {useDetection && (
            <Typography variant="body2" style={{ marginTop: '10px', color: 'blue' }}>
              Esta foto fue tomada utilizando detección automática con inteligencia artificial (función experimental).
            </Typography>
          )}
          <Box mt={2} display="flex" justifyContent="space-between" flexWrap="wrap">
            <Button variant="contained" onClick={handleRetake} className="secondary-button" style={{ marginBottom: '10px', flex: '1 0 45%' }}>
              <CameraAltIcon style={{ marginRight: '10px' }} /> Repetir
            </Button>
            <Button variant="contained" onClick={handleConfirm} className="primary-button" style={{ marginBottom: '10px', flex: '1 0 45%' }}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>
      </Box>
    </Container>
  );
};

export default Camera;