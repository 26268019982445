import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Typography, Box } from '@mui/material';
import logoBlanco from '../images/logo_blanco.svg';
import logoProsys from '../images/logoprosys.png';
import portadaImage from '../images/portada.png';

const Slides = () => {
  const history = useHistory();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleContinue = () => {
    history.push('/checklist');
  };

  return (
    <Container 
      className="slide-container" 
      maxWidth={false} 
      disableGutters
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        boxSizing: 'border-box',
        overflow: 'hidden'
      }}
    >
      <Box 
        className={`logo-box ${showContent ? 'logo-box-animate' : ''}`}
        sx={{ 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center',
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <img src={logoBlanco} alt="Flowsure Logo" className="logo" style={{ maxWidth: '80%', maxHeight: '15vh' }} />
      </Box>
      
      <Box 
        className={`welcome-content ${showContent ? 'welcome-content-animate' : ''}`}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          maxWidth: '400px',
          marginTop: '20px',
        }}
      >
        <Typography variant="h5" color="#F9FCFF" align="center">Bienvenido</Typography>
        <Typography 
          variant="body1" 
          color="#F9FCFF" 
          align="center" 
          sx={{ 
            margin: '10px 0 40px',
            opacity: 0.8, 
            fontSize: '0.9rem',
            maxWidth: '90%'
          }}
        >
          FlowSure es un producto que integra inteligencia
          artificial para optimizar los procesos de
          reconocimiento de datos e imágenes
        </Typography>
        <Box sx={{ margin: '0', textAlign: 'center', width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}> 
          <img 
            src={portadaImage} 
            alt="Portada" 
            style={{ 
              maxWidth: '100%', 
              height: 'auto', 
              maxHeight: '35vh',
              objectFit: 'contain'
            }} 
          />
        </Box>
      </Box>
      
      <Box 
        className="button-container" 
        sx={{ 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center', 
          flexGrow: 0, 
          flexShrink: 0,
          marginTop: '10px'
        }}
      >
        <Button 
          variant="contained" 
          onClick={handleContinue} 
          className={`continue-button ${showContent ? 'continue-button-animate' : ''}`}
          sx={{ width: '90%', maxWidth: '350px' }}
        >
          Continuar
        </Button>
      </Box>
      
      <Box 
        className={`prosis-box ${showContent ? 'prosis-box-animate' : ''}`}
        sx={{ 
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center',
          flexGrow: 0,
          flexShrink: 0,
          marginTop: '10px'
        }}
      >
        <img src={logoProsys} alt="Prosys Logo" className="prosis-logo" style={{ maxWidth: '60%', maxHeight: '8vh' }} />
      </Box>
    </Container>
  );
};

export default Slides;