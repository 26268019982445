import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { keyframes } from '@emotion/react';
import { ReactComponent as FlowsureLogo } from '../images/icono.svg';

// Definición de la animación 'pulse' utilizando keyframes
const pulse = keyframes`
  0% {
    transform: scale(0.95);
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.7));
  }
  
  70% {
    transform: scale(1);
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0));
  }
  
  100% {
    transform: scale(0.95);
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
  }
`;

// Componente de animación de carga
const LoadingAnimation = () => {
  return (
    // Contenedor principal para centrar el contenido
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#F9FCFF"
    >
      {/* Contenedor para el logo con animación de pulso */}
      <Box
        sx={{
          width: '150px',
          height: '150px',
          animation: `${pulse} 2s infinite`,
        }}
      >
        <FlowsureLogo width="100%" height="100%" />
      </Box>
      {/* Indicador circular de progreso */}
      <CircularProgress 
        size={60} 
        thickness={4} 
        sx={{ 
          color: '#261d56',
          marginTop: 2
        }} 
      />
    </Box>
  );
};

export default LoadingAnimation;
