import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Box, Fade, Button, IconButton, Tooltip, Divider, Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import LoadingAnimation from './LoadingAnimation';
import { ReactComponent as FlowsureLogo } from '../images/icono.svg';
import DanosDetalle from './DamageDetalle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const AnalisisGeneral = ({ resultados: resultadosProp, onResetAnalisis, onResetInspeccion }) => {
  const [loading, setLoading] = useState(true);
  const [expandedImage, setExpandedImage] = useState(null);
  const [showDanos, setShowDanos] = useState(false);
  const history = useHistory();
  const [resultados, setResultados] = useState(null);
  const [copiedField, setCopiedField] = useState('');

  const expectedFields = [
    'Nombre_adquiriente', 'Rut_adquiriente', 'Marca', 'Modelo_vehiculo', 
    'Patente', 'Año', 'Numero_chasis', 'Numero_motor', 'Color', 'Transmision'
  ];

  const copyToClipboard = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(field);
      setTimeout(() => setCopiedField(''), 2000);
    }, (err) => {
      console.error('Error al copiar texto: ', err);
    });
  };

  useEffect(() => {
    if (resultados) {
      const timer = setTimeout(() => setLoading(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [resultados]);

  useEffect(() => {
    if (resultadosProp) {
      setResultados(resultadosProp);
      setLoading(false);
    } else {
      const savedResults = localStorage.getItem('imagenesProcessadas');
      if (savedResults) {
        const parsedResults = JSON.parse(savedResults);
        // Recuperar las imágenes completas del localStorage
        for (let i = 0; i < 11; i++) {
          const fullImage = localStorage.getItem(`photo_${i}`);
          if (fullImage) {
            if (i < 4 && parsedResults.danos && parsedResults.danos[i]) {
              parsedResults.danos[i].imagen = fullImage;
            } else if (i === 7 && parsedResults.odometro) {
              parsedResults.odometro.imagen = fullImage;
            } else if (i === 9 && parsedResults.chasis) {
              parsedResults.chasis.imagen = fullImage;
            } else if (i === 10 && parsedResults.documento) {
              parsedResults.documento.imagen = fullImage;
            }
          }
        }
        setResultados(parsedResults);
        setLoading(false);
      } else {
        history.push('/process-images');
      }
    }
  }, [resultadosProp, history]);

  const fuentesImagenes = {
    0: 'Foto Frontal',
    1: 'Foto Trasera',
    2: 'Foto Lateral Izquierda',
    3: 'Foto Lateral Derecha',
    7: 'Foto Odómetro',
    9: 'Foto Chasis',
    10: 'Foto Documento'
  };

  if (loading) {
    return <LoadingAnimation />;
  }

  if (showDanos) {
    return <DanosDetalle 
      danos={resultados.danos} 
      onBack={() => setShowDanos(false)} 
      resultados={resultados}
      onResetInspeccion={onResetInspeccion}
    />;
  }

  if (!resultados) {
    return <Typography>No se encontraron resultados. Por favor, vuelva a procesar las imágenes.</Typography>;
  }

  const ResultBox = ({ title, children }) => (
    <Box 
      className="result-box" 
      p={3} 
      borderRadius={2} 
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      bgcolor="white" 
      mb={4}
    >
      <Typography variant="h6" style={{ color: '#261d56', marginBottom: '20px', fontWeight: 'bold' }}>{title}</Typography>
      {children}
    </Box>
  );

  const NoDetectionMessage = ({ message }) => (
    <Typography 
      variant="body1" 
      style={{ 
        color: '#666', 
        backgroundColor: '#f0f4f8', 
        padding: '10px', 
        borderRadius: '4px',
        textAlign: 'center'
      }}
    >
      {message}
    </Typography>
  );

  const ImageModal = ({ image, onClose }) => (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <img
        src={image}
        alt="Expanded view"
        style={{
          maxWidth: '95%',
          maxHeight: '95%',
          objectFit: 'contain',
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </Box>
  );

  const ExpandableImage = ({ src, alt }) => (
    <img 
      src={src} 
      alt={alt} 
      style={{ 
        maxWidth: '100%', 
        borderRadius: '8px', 
        cursor: 'pointer' 
      }}
      onClick={() => setExpandedImage(src)}
    />
  );

  const renderJsonData = (data, prefix = '') => {
    if (typeof data !== 'object' || data === null) {
      // Nombre de la clase de daño aquí
      const displayValue = data === 'Car-Damage' ? 'Daño del vehículo' : data;
      return (
        <Box display="flex" alignItems="center" marginBottom={1} key={prefix}>
          <Typography variant="body1" style={{ color: '#333', flex: 1 }}>
            <strong style={{ color: '#261d56' }}>{prefix}:</strong> {displayValue}
          </Typography>
          <Tooltip title={copiedField === prefix ? "¡Copiado!" : "Copiar"}>
            <IconButton onClick={() => copyToClipboard(displayValue, prefix)} size="small">
              <ContentCopyIcon fontSize="small" style={{ color: '#261d56' }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
  
    return Object.entries(data).map(([key, value]) => {
      if (value === null || value === undefined) return null;
      const newPrefix = prefix ? `${prefix} ${key}` : key;
      let displayKey = key.replace(/^(Propietario|Vehiculo)\s/, '');
      
      if (key === 'Propietario' || key === 'Vehiculo') {
        return (
          <React.Fragment key={newPrefix}>
            {prefix && <Divider style={{ margin: '10px 0' }} />}
            <Typography variant="subtitle1" style={{ color: '#261d56', fontWeight: 'bold', marginTop: '10px', textTransform: 'capitalize' }}>
              {key}:
            </Typography>
            <Box paddingLeft={2}>
              {renderJsonData(value, '')}
            </Box>
          </React.Fragment>
        );
      }
  
      if (typeof value === 'object') {
        // Si es un objeto de daño, la visualización aquí
        if (value.class === 'Car-Damage') {
          return (
            <Box key={newPrefix} marginBottom={1}>
              <Typography variant="subtitle1" style={{ color: '#261d56', fontWeight: 'bold' }}>
                Daño del vehículo:
              </Typography>
              <Box paddingLeft={2}>
                {renderJsonData(value, '')}
              </Box>
            </Box>
          );
        }
        return (
          <Box key={newPrefix} marginBottom={1}>
            <Typography variant="subtitle1" style={{ color: '#261d56', fontWeight: 'bold' }}>
              {displayKey}:
            </Typography>
            <Box paddingLeft={2}>
              {renderJsonData(value, '')}
            </Box>
          </Box>
        );
      }
      return renderJsonData(value, displayKey);
    }).filter(Boolean);
  };

  const formatExtractedText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const renderExtractedData = (title, data) => (
    <Box marginBottom={2}>
      <Typography variant="h6" style={{ color: '#261d56', marginBottom: '10px' }}>Datos extraídos:</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" style={{ color: '#333', flex: 1 }}>
          <strong>{title}:</strong> {formatExtractedText(data)}
        </Typography>
        <Tooltip title={copiedField === title ? "¡Copiado!" : "Copiar"}>
          <IconButton onClick={() => copyToClipboard(data, title)} size="small">
            <ContentCopyIcon fontSize="small" style={{ color: '#261d56' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );

  const renderDocumentoData = (documentoData) => {
    if (!documentoData || typeof documentoData !== 'object') {
      return <NoDetectionMessage message="No se pudieron extraer datos del documento." />;
    }
  
    const extractedFields = [];
    const missingFields = [];
  
    expectedFields.forEach(field => {
      let value = null;
      if (documentoData.propietario && field in documentoData.propietario) {
        value = documentoData.propietario[field];
      } else if (documentoData.vehiculo && field in documentoData.vehiculo) {
        value = documentoData.vehiculo[field];
      } else if (field in documentoData) {
        value = documentoData[field];
      }
  
      if (value !== undefined && value !== null && value !== '') {
        extractedFields.push(field);
      } else {
        missingFields.push(field);
      }
    });
  
    const extractedFieldsCount = extractedFields.length;
    const showWarning = extractedFieldsCount <= 5;
  
    return (
      <>
        {showWarning && (
          <Alert severity="warning" style={{ marginBottom: '15px' }}>
            Advertencia: Al parecer el documento no es válido o no se han podido extraer todos los datos necesarios. 
            Se extrajeron solo {extractedFieldsCount} de 10 campos esperados.
            {missingFields.length > 0 && ` Faltan los siguientes campos: ${missingFields.join(', ')}.`}
          </Alert>
        )}
        {renderJsonData(documentoData)}
      </>
    );
  };
  return (
    <Fade in={!loading}>
      <Container className="container" style={{ backgroundColor: '#F9FCFF', minHeight: '100vh', padding: '20px' }}>
        <Box textAlign="center" mb={4}>
          <Box width="150px" height="150px" margin="20px auto">
            <FlowsureLogo width="100%" height="100%" />
          </Box>
          <Typography variant="h4" style={{ color: '#261d56', fontWeight: 'bold' }}>
            Análisis General
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ResultBox title="Documento">
              {resultados.documento && resultados.documento.imagen ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" style={{ color: '#444', marginBottom: '10px' }}>
                      Fuente: {fuentesImagenes[10]}
                    </Typography>
                    <ExpandableImage src={resultados.documento.imagen} alt="Documento" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" style={{ color: '#261d56', marginBottom: '10px' }}>Datos extraídos:</Typography>
                    {renderDocumentoData(resultados.documento.datos)}
                  </Grid>
                </Grid>
              ) : (
                <NoDetectionMessage message="No se encontró la imagen del documento." />
              )}
            </ResultBox>
          </Grid>
  
          <Grid item xs={12}>
            <ResultBox title="Odómetro">
              {resultados.odometro ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" style={{ color: '#444', marginBottom: '10px' }}>Fuente: {fuentesImagenes[7]}</Typography>
                    <ExpandableImage src={resultados.odometro.imagenRecortada || resultados.odometro.imagen} alt="Odómetro" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {resultados.odometro.datos ? (
                      renderExtractedData("Lectura del odómetro", resultados.odometro.datos.extracted_text)
                    ) : (
                      <Typography variant="body1" style={{ color: '#333' }}>
                        No se pudieron extraer datos del odómetro.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <NoDetectionMessage message="No se detectó el odómetro." />
              )}
            </ResultBox>
          </Grid>
          <Grid item xs={12}>
            <ResultBox title="Patentes Detectadas">
              {resultados.patentes && resultados.patentes.length > 0 ? (
                <Grid container spacing={3}>
                  {resultados.patentes.map((patente, index) => (
                    <Grid item xs={12} key={index}>
                      <Box textAlign="center" p={2} borderRadius={2} bgcolor="#f0f4f8">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2" style={{ color: '#444', marginBottom: '10px' }}>
                              Fuente: {fuentesImagenes[patente.indice]}
                            </Typography>
                            <ExpandableImage src={patente.imagenRecortada} alt={`Patente ${index + 1}`} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {patente.datos ? (
                              renderExtractedData(`Patente ${index + 1}`, patente.datos.extracted_text)
                            ) : (
                              <Typography variant="body1" style={{ color: '#333' }}>
                                No se pudieron extraer datos de la patente.
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      </Grid>
                  ))}
                </Grid>
              ) : (
                <NoDetectionMessage message="No se detectaron patentes." />
              )}
            </ResultBox>
          </Grid>
  
          <Grid item xs={12}>
            <ResultBox title="Chasis Detectado">
              {resultados.chasis ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" style={{ color: '#444', marginBottom: '10px' }}>Fuente: {fuentesImagenes[9]}</Typography>
                    <ExpandableImage 
                      src={resultados.chasis.datos && resultados.chasis.datos.extracted_text 
                        ? resultados.chasis.imagenRecortada 
                        : resultados.chasis.imagenOriginal} 
                      alt="Chasis" 
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  {resultados.chasis.datos && resultados.chasis.datos.extracted_text ? (
                    renderExtractedData("Chasis", resultados.chasis.datos.extracted_text)
                  ) : (
                    <Typography variant="body1" style={{ color: '#333' }}>
                      No se pudieron extraer datos del Chasis.
                    </Typography>
                  )}
                  </Grid>
                </Grid>
              ) : (
                <NoDetectionMessage message="No se detectó el Chasis." />
              )}
            </ResultBox>
          </Grid>
  
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              className="primary-button"
              fullWidth 
              onClick={() => setShowDanos(true)}
              style={{ marginTop: '20px' }}
            >
              Ver Daños Detectados
            </Button>
          </Grid>
        </Grid>
        {expandedImage && (
          <ImageModal 
            image={expandedImage} 
            onClose={() => setExpandedImage(null)}
          />
        )}
      </Container>
    </Fade>
  );
};

export default AnalisisGeneral;