import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, Grid, Box, Button, Fade } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ReactComponent as FlowsureLogo } from '../images/icono.svg';
import InformePDF from './InformePDF';

const DanosDetalle = ({ danos, onBack, resultados, onResetInspeccion }) => {
  const [expandedImage, setExpandedImage] = useState(null);
  const history = useHistory();
  const logoRef = useRef(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (logoRef.current) {
      const svgString = new XMLSerializer().serializeToString(logoRef.current);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        setLogo(canvas.toDataURL("image/png"));
      };
      img.src = "data:image/svg+xml;base64," + btoa(svgString);
    }
  }, []);

  const handleNuevaInspeccion = () => {
    // Limpiar todo el localStorage
    localStorage.clear();
    
    // Llamar a la función de reinicio proporcionada por el componente padre
    if (onResetInspeccion) {
      onResetInspeccion();
    }
    
    // Redirigir al inicio
    history.push('/');
  };

  const { generarInforme } = InformePDF({ resultados, logo });

  const handleGenerarInforme = () => {
    if (resultados) {
      generarInforme();
    } else {
      console.error('No hay resultados disponibles para generar el informe');
    }
  };

  const fuentesImagenes = {
    0: 'Foto Frontal',
    1: 'Foto Trasera',
    2: 'Foto Lateral Izquierda',
    3: 'Foto Lateral Derecha',
  };

  return (
    <Fade in={true}>
      <Container className="container" style={{ backgroundColor: '#F9FCFF', minHeight: '100vh', padding: '20px' }}>
        <Box textAlign="center" mb={4}>
          <Box width="150px" height="150px" margin="20px auto">
            <FlowsureLogo ref={logoRef} width="100%" height="100%" />
          </Box>
          <Typography variant="h4" style={{ color: '#261d56', fontWeight: 'bold' }}>
            Daños Detectados
          </Typography>
        </Box>
        <Box mt={4} mb={4} display="flex" justifyContent="space-between">
          <Button 
            variant="contained" 
            className="primary-button"
            onClick={onBack}
            style={{ flex: 1, marginRight: '10px' }}
          >
            Volver al Análisis General
          </Button>
          <Button 
            variant="contained" 
            className="primary-button"
            onClick={handleNuevaInspeccion}
            style={{ flex: 1, marginRight: '10px' }}
          >
            Realizar Nueva Inspección
          </Button>
          <Button 
            variant="contained" 
            className="primary-button"
            onClick={handleGenerarInforme}
            style={{ flex: 1 }}
          >
            Descargar Informe PDF
          </Button>
        </Box>
        <Box className="result-box" p={3} borderRadius={2} boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)" bgcolor="white" mb={4}>
          <Typography variant="h6" style={{ color: '#261d56', marginBottom: '20px', fontWeight: 'bold' }}>Daños Detectados</Typography>
          {danos && danos.length > 0 ? (
            <Grid container spacing={3}>
              {danos.flatMap((dano, index) => 
                dano.daños.map((d, i) => (
              <Grid item xs={12} sm={6} md={4} key={`${index}-${i}`}>
                <Box textAlign="center" p={2} borderRadius={2} bgcolor="#f0f4f8">
                  <Typography variant="body1" style={{ color: '#261d56', marginBottom: '10px' }}>{d.class}</Typography>
                  <Typography variant="body2" style={{ color: '#666', marginBottom: '5px' }}>
                    Fuente: {fuentesImagenes[dano.indice]}
                  </Typography>
                  <img 
                    src={d.imagenRecortada} 
                    alt={`Daño ${index + 1}-${i + 1}`}
                    style={{ 
                      maxWidth: '100%', 
                      borderRadius: '8px', 
                      cursor: 'pointer' 
                    }}
                    onClick={() => setExpandedImage(d.imagenRecortada)}
                  />
                </Box>
              </Grid>
                ))
              )}
            </Grid>
          ) : (
            <Typography 
              variant="body1" 
              style={{ 
                color: '#666', 
                backgroundColor: '#f0f4f8', 
                padding: '10px', 
                borderRadius: '4px',
                textAlign: 'center'
              }}
            >
              No se detectaron daños.
            </Typography>
          )}
        </Box>
        {expandedImage && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
            onClick={() => setExpandedImage(null)}
          >
            <img
              src={expandedImage}
              alt="Expanded view"
              style={{
                maxWidth: '90%',
                maxHeight: '90%',
                objectFit: 'contain',
              }}
              onClick={(e) => e.stopPropagation()}
            />
          </Box>
        )}
      </Container>
    </Fade>
  );
};

export default DanosDetalle;
