import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, LinearProgress, Fade } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as FlowsureLogo } from '../images/icono.svg';

// Estilo de la caja principal
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  backgroundColor: '#F9FCFF',
  padding: theme.spacing(3),
}));

// Estilo del contenedor del logo
const LogoContainer = styled(Box)({
  width: '100px',
  height: '100px',
  marginBottom: '2rem',
  animation: 'pulse 2s infinite',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      filter: 'drop-shadow(0 0 0 rgba(42, 42, 192, 0.7))',
    },
    '70%': {
      transform: 'scale(1)',
      filter: 'drop-shadow(0 0 10px rgba(42, 42, 192, 0))',
    },
    '100%': {
      transform: 'scale(0.95)',
      filter: 'drop-shadow(0 0 0 rgba(42, 42, 192, 0))',
    },
  },
});

const ProcessImages = ({ setResultadosAnalisis, totalFotosRequeridas }) => {
  const history = useHistory();
  const [processingStatus, setProcessingStatus] = useState('');
  const [progress, setProgress] = useState(0);

  const getAuthToken = useCallback(async () => {
    try {
      const response = await axios.post('https://ocr.flowsure.cl/auth/token', {
        username: process.env.REACT_APP_API_USERNAME,
        password: process.env.REACT_APP_API_PASSWORD
      });
      return response.data.access_token;
    } catch (error) {
      console.error('Error al obtener el token de autenticación:', error);
      throw error;
    }
  }, []);

  const checkAndRefreshToken = useCallback(async () => {
    return await getAuthToken();
  }, [getAuthToken]);

  // Función para formatear la patente
  const formatearPatente = (patente) => {
    let patenteFormateada = patente.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    if (patenteFormateada.length > 6) {
      patenteFormateada = patenteFormateada.slice(0, 6);
    } else if (patenteFormateada.length < 6) {
      patenteFormateada = patenteFormateada.padEnd(6, 'X');
    }
    return patenteFormateada.slice(0, 4) + patenteFormateada.slice(4);
  };

  const limpiarLecturaOdometro = (lectura) => {
    if (!lectura) return 'No legible';
    
    lectura = lectura.toUpperCase().replace(/\s+/g, ' ').replace(/\n/g, ' ').trim();
    
    const patronOdometro = /\b(\d{1,3}(?:,?\d{3})*|\d+)(?:\s*KM|\s*M|\s*MILES)?\b/gi;
    const coincidencias = [...lectura.matchAll(patronOdometro)];
    
    if (coincidencias.length > 0) {
      const lecturasPosibles = coincidencias
        .map(m => parseInt(m[1].replace(/,/g, ''), 10))
        .filter(num => num >= 1000 && num <= 999999)
        .sort((a, b) => b - a);
      
      if (lecturasPosibles.length > 0) {
        return `${lecturasPosibles[0]} km`;
      }
    }
    
    const numeros = lectura.match(/\b\d{4,6}\b/g);
    if (numeros) {
      const numerosFiltrados = numeros
        .map(num => parseInt(num, 10))
        .filter(num => num >= 1000 && num <= 999999)
        .sort((a, b) => b - a);
      
      if (numerosFiltrados.length > 0) {
        return `${numerosFiltrados[0]} km`;
      }
    }
    
    return 'No legible';
  };

  const procesarImagen = useCallback(async (image, tipo) => {
    const token = await checkAndRefreshToken();
    try {
      console.log(`Procesando imagen tipo documento ${tipo}`);
      const response = await axios({
        method: 'POST',
        url: 'https://ocr.flowsure.cl/extractor/',
        data: {
          image_base64: image.split(',')[1],
          tipo_documento: tipo
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(`Respuesta de la API para ${tipo}:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`Error al procesar la imagen de ${tipo}:`, error);
      return null;
    }
  }, [checkAndRefreshToken]);

  const procesarDocumento = useCallback(async (image) => {
    const token = await checkAndRefreshToken();
    try {
      console.log('Procesando imagen del documento');
      const response = await axios({
        method: 'POST',
        url: 'https://ocr.flowsure.cl/extractor_ia/',
        data: { image_base64: image.split(',')[1] },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Respuesta de la API de OCR:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al procesar la imagen del documento:', error);
      return null;
    }
  }, [checkAndRefreshToken]);

  const recortarImagen = (imagenBase64, coordenadas) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        if (!coordenadas || !coordenadas.x || !coordenadas.y || !coordenadas.width || !coordenadas.height) {
          console.log('Coordenadas no válidas, devolviendo imagen original');
          resolve(imagenBase64);
          return;
        }
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const { x, y, width, height } = coordenadas;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, x - width/2, y - height/2, width, height, 0, 0, width, height);
        resolve(canvas.toDataURL('image/jpeg', 0.8));
      };
      img.src = imagenBase64;
    });
  };

  // Función para traducir las clases de daños al español
  const traducirClaseDano = (claseEnIngles) => {
    const traducciones = {
      'Front-Windscreen-Damage': 'Daño en Parabrisas Delantero',
      'Headlight-Damage': 'Daño en Faro Delantero',
      'Rear-windscreen-Damage': 'Daño en Parabrisas Trasero',
      'RunningBoard-Dent': 'Abolladura en Estribo',
      'Sidemirror-Damage': 'Daño en Espejo Lateral',
      'Signlight-Damage': 'Daño en Luz de Señalización',
      'Taillight-Damage': 'Daño en Luz Trasera',
      'bonnet-dent': 'Abolladura en Capó',
      'doorouter-dent': 'Abolladura en Puerta Exterior',
      'fender-dent': 'Abolladura en Guardabarros',
      'front-bumper-dent': 'Abolladura en Parachoques Delantero',
      'pillar-dent': 'Abolladura en Pilar',
      'quaterpanel-dent': 'Abolladura en Panel Lateral',
      'rear-bumper-dent': 'Abolladura en Parachoques Trasero',
      'roof-dent': 'Abolladura en Techo'
    };
    return traducciones[claseEnIngles] || claseEnIngles;
  };

  const procesarDanos = useCallback(async (image) => {
    try {
      console.log('Enviando foto a la API de detección de daños');
      const response = await axios({
        method: "POST",
        url: "https://detect.roboflow.com/zxczada/1",
        params: {
          api_key: "jyaN1Gu2sGt82xEgVZHT"
        },
        data: image,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      console.log('Respuesta de la API de detección de daños:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al procesar la imagen de daños:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    const processImages = async () => {
      const procesadoAnterior = localStorage.getItem('imagenesProcessadas');
      if (procesadoAnterior) {
        const resultadosAnteriores = JSON.parse(procesadoAnterior);
        setResultadosAnalisis(resultadosAnteriores);
        setProgress(100);
        setProcessingStatus('Análisis completado (datos recuperados)');
        setTimeout(() => {
          history.push('/analisis-general');
        }, 1500);
        return;
      }

      const resultados = {
        danos: [],
        odometro: null,
        patentes: [],
        chasis: null,
        documento: null
      };

      const totalSteps = 8;
      let currentStep = 0;

      const updateProgress = (step, message) => {
        currentStep = step;
        setProgress((currentStep / totalSteps) * 100);
        setProcessingStatus(message);
      };
      
      updateProgress(1, 'Iniciando análisis de imágenes...');
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Procesar fotos de ángulos para daños y patentes
      for (let i = 0; i < 4; i++) {
        updateProgress(i + 2, `Analizando foto ${i + 1} de 4...`);
        const image = localStorage.getItem(`photo_${i}`);
        if (image) {
          try {
            // Procesar daños
            const responseDanos = await procesarDanos(image);
            if (responseDanos && responseDanos.predictions && responseDanos.predictions.length > 0) {
              const prediccionesFiltradas = responseDanos.predictions.filter(pred => pred.confidence >= 0.5);
              const danosConRecortes = await Promise.all(prediccionesFiltradas.map(async (dano) => ({
                ...dano,
                class: traducirClaseDano(dano.class),
                imagenRecortada: await recortarImagen(image, dano)
              })));
              if (danosConRecortes.length > 0) {
                resultados.danos.push({
                  imagen: image,
                  indice: i,
                  daños: danosConRecortes
                });
              }
            }

            // Procesar patentes (solo para fotos frontal y trasera)
            if (i === 0 || i === 1) {
              const patente = await procesarImagen(image, 4); // tipo_documento 4 para patentes
              if (patente && patente.extracted_text) {
                const imagenRecortada = await recortarImagen(image, patente.best_prediction);
                resultados.patentes.push({
                  imagen: image,
                  imagenRecortada,
                  datos: {
                    extracted_text: formatearPatente(patente.extracted_text)
                  },
                  coordenadas: patente.best_prediction
                });
              }
            }
          } catch (error) {
            console.error(`Error al analizar la foto ${i}:`, error);
          }
        }
      }

      updateProgress(6, 'Procesando odómetro...');
      const odometroImage = localStorage.getItem('photo_7');
      if (odometroImage) {
        try {
          const odometroResultado = await procesarImagen(odometroImage, 2); // tipo_documento 2 para odómetro
          if (odometroResultado && odometroResultado.roboflow_result && odometroResultado.roboflow_result.predictions) {
            const odometrosDetectados = odometroResultado.roboflow_result.predictions.filter(pred => pred.class === "odometer");
            if (odometrosDetectados.length > 0) {
              // Seleccionar el odómetro con la confianza más alta
              const mejorOdometro = odometrosDetectados.reduce((prev, current) => 
                (prev.confidence > current.confidence) ? prev : current
              );
              
              const imagenRecortada = await recortarImagen(odometroImage, mejorOdometro);
              resultados.odometro = {
                imagen: odometroImage,
                imagenRecortada,
                datos: {
                  extracted_text: limpiarLecturaOdometro(odometroResultado.extracted_text)
                },
                coordenadas: mejorOdometro
              };
            } else {
              // No se detectó un odómetro específicamente, pero aún tenemos texto extraído
              resultados.odometro = {
                imagen: odometroImage,
                imagenRecortada: odometroImage,
                datos: {
                  extracted_text: limpiarLecturaOdometro(odometroResultado.extracted_text)
                },
                coordenadas: null
              };
            }
          } else {
            // No se obtuvo una respuesta válida de la API
            resultados.odometro = {
              imagen: odometroImage,
              imagenRecortada: odometroImage,
              datos: {
                extracted_text: 'No legible'
              },
              coordenadas: null
            };
          }
          console.log('Datos de odómetro procesados:', resultados.odometro);
        } catch (error) {
          console.error("Error al analizar la foto del odómetro:", error);
          resultados.odometro = {
            imagen: odometroImage,
            imagenRecortada: odometroImage,
            datos: {
              extracted_text: 'Error en el procesamiento'
            },
            coordenadas: null
          };
        }
      }

      // Procesamiento del Chasis
      updateProgress(7, 'Procesando Chasis...');
      const chasisImage = localStorage.getItem('photo_9');
      if (chasisImage) {
        try {
          const chasisResultado = await procesarImagen(chasisImage, 3); // tipo_documento 3 para chasis
          if (chasisResultado && chasisResultado.roboflow_result && chasisResultado.roboflow_result.predictions) {
            const chasisDetectados = chasisResultado.roboflow_result.predictions.filter(pred => pred.class === "vin");
            if (chasisDetectados.length > 0) {
              // Seleccionar el chasis con la confianza más alta
              const mejorChasis = chasisDetectados.reduce((prev, current) => 
                (prev.confidence > current.confidence) ? prev : current
              );
              
              const imagenRecortada = await recortarImagen(chasisImage, mejorChasis);
              resultados.chasis = {
                imagen: chasisImage,
                imagenRecortada,
                datos: {
                  extracted_text: chasisResultado.extracted_text
                },
                coordenadas: mejorChasis
              };
            } else {
              // No se detectó un chasis específicamente, pero aún tenemos texto extraído
              resultados.chasis = {
                imagen: chasisImage,
                imagenRecortada: chasisImage,
                datos: {
                  extracted_text: chasisResultado.extracted_text
                },
                coordenadas: null
              };
            }
          } else {
            // No se obtuvo una respuesta válida de la API
            resultados.chasis = {
              imagen: chasisImage,
              imagenRecortada: chasisImage,
              datos: {
                extracted_text: 'No legible'
              },
              coordenadas: null
            };
          }
        } catch (error) {
          console.error("Error al procesar la foto del Chasis:", error);
          resultados.chasis = {
            imagen: chasisImage,
            imagenRecortada: chasisImage,
            datos: {
              extracted_text: 'Error en el procesamiento'
            },
            coordenadas: null
          };
        }
      }
      
      // Procesamiento del documento
      updateProgress(8, 'Procesando documento...');
      const documentoImage = localStorage.getItem('photo_10');
      if (documentoImage) {
        try {
          const documentoResultado = await procesarDocumento(documentoImage);
          resultados.documento = {
            imagen: documentoImage,
            datos: documentoResultado || {}
          };
          console.log('Datos de documento procesados:', documentoResultado);
        } catch (error) {
          console.error("Error al procesar la foto del documento:", error);
          resultados.documento = {
            imagen: documentoImage,
            datos: {}
          };
        }
      }

      console.log('Resultados finales:', resultados);
      setResultadosAnalisis(resultados);
      localStorage.setItem('imagenesProcessadas', JSON.stringify(resultados));
      history.push('/analisis-general');
    };

    processImages();
  }, [setResultadosAnalisis, history, totalFotosRequeridas, procesarDocumento, procesarImagen, procesarDanos]);

  return (
    <StyledBox>
      <LogoContainer>
        <FlowsureLogo width="100%" height="100%" />
      </LogoContainer>
      <Typography variant="h4" gutterBottom style={{ color: '#2A2AC0', fontWeight: 'bold' }}>
        Procesando Imágenes
      </Typography>
      <Typography variant="body1" gutterBottom style={{ marginBottom: '2rem', textAlign: 'center' }}>
        {processingStatus}
      </Typography>
      <Box width="80%" maxWidth="400px">
        <LinearProgress variant="determinate" value={progress} style={{ height: 10, borderRadius: 5 }} />
      </Box>
      <Fade in={progress === 100} style={{ transitionDelay: '500ms' }}>
        <Typography variant="body1" style={{ marginTop: '2rem', fontWeight: 'bold' }}>
          ¡Análisis completado!
        </Typography>
      </Fade>
    </StyledBox>
  );
};

export default ProcessImages;