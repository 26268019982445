import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Container, Typography, Box, Modal } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const photoTypes = [
  'Foto frontal del vehículo',
  'Foto vista trasera del vehículo',
  'Foto perfil izquierdo vehículo',
  'Foto perfil derecho vehículo',
  'Motor del vehículo',
  'Volante del vehículo',
  'Panel del vehículo',
  'Kilometraje vehículo',
  'Rueda de repuesto',
  'Número de chasis',
  'Documento del vehículo'
];

const PhotoReview = () => {
  const history = useHistory();
  const location = useLocation();
  const { photoType, photoData } = location.state || {};
  const [zoom, setZoom] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Precargar la imagen para mejorar el rendimiento
    if (photoData) {
      const img = new Image();
      img.src = photoData;
    }
  }, [photoData]);

  const handleConfirm = () => {
    history.push({
      pathname: '/checklist',
      state: { completed: photoType }
    });
  };

  const handleZoomIn = () => setZoom(prev => Math.min(prev + 0.1, 3));
  const handleZoomOut = () => setZoom(prev => Math.max(prev - 0.1, 0.5));

  if (photoType === undefined || !photoData) {
    return (
      <Container className="container">
        <Box mt={5}>
          <Typography variant="h5">No hay foto para revisar</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container className="container">
      <Box mt={5}>
        <Typography variant="h5">Revisión de la Foto</Typography>
        <Box mt={2}>
          <Typography variant="body1">{photoTypes[photoType]}</Typography>
          <Box 
            mt={2} 
            sx={{ 
              position: 'relative', 
              width: '100%', 
              height: '50vh', 
              overflow: 'hidden',
              cursor: 'pointer'
            }}
            onClick={() => setModalOpen(true)}
          >
            <img 
              src={photoData} 
              alt="Foto capturada" 
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                transform: `scale(${zoom})`,
                transition: 'transform 0.2s ease-in-out'
              }}
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <Button onClick={handleZoomOut} disabled={zoom <= 0.5}>
              <ZoomOutIcon />
            </Button>
            <Button onClick={handleZoomIn} disabled={zoom >= 3}>
              <ZoomInIcon />
            </Button>
          </Box>
          <Button variant="contained" color="primary" onClick={handleConfirm} fullWidth sx={{ mt: 2 }}>
            Confirmar y Continuar
          </Button>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <img 
            src={photoData} 
            alt="Foto capturada en alta resolución" 
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
      </Modal>
    </Container>
  );
};

export default PhotoReview;