import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Typography, Grid, Box, Modal, Button } from '@mui/material';
import Speedometer from './Speedometer';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import perfilIzquierdo from '../images/perfilizquierdo.svg';
import trasero from '../images/trasero.svg';
import frente from '../images/frente.svg';
import perfilDerecho from '../images/perfilderecho.svg';
import volante from '../images/volante.png';
import chasis from '../images/chasis.png';
import documento from '../images/documento.png';
import motor from '../images/Motor.png';
import odometro from '../images/odometro.png';
import panel from '../images/panel.png';
import rueda from '../images/rueda.png';
import logo from '../images/logo color.svg';

// Definir los tipos de fotos iniciales
const initialPhotoTypes = [
  { title: 'Foto frontal del vehículo', img: frente },
  { title: 'Foto vista trasera del vehículo', img: trasero },
  { title: 'Foto perfil izquierdo del vehículo', img: perfilIzquierdo },
  { title: 'Foto perfil derecho del vehículo', img: perfilDerecho },
  { title: 'Motor del vehículo', img: motor },
  { title: 'Volante del vehículo', img: volante },
  { title: 'Panel del vehículo', img: panel },
  { title: 'Kilometraje del vehículo', img: odometro },
  { title: 'Rueda de repuesto', img: rueda },
  { title: 'Número de chasis', img: chasis },
  { title: 'Documento del vehículo', img: documento }
];

// Función para determinar si se usa detección automática
const isAutoDetectionEnabled = (photoType) => {
  return ['0', '1', '2', '3', '9'].includes(photoType.toString());
};

const PhotoChecklist = ({ photos, onSavePhoto }) => {
  const [checked, setChecked] = useState(() => new Array(initialPhotoTypes.length).fill(false));
  const [open, setOpen] = useState(false);
  const [completionModalOpen, setCompletionModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const updateChecked = useCallback((index, value) => {
    setChecked(prevChecked => {
      const newChecked = [...prevChecked];
      newChecked[index] = value;
      return newChecked;
    });
  }, []);

  useEffect(() => {
    if (location.state && location.state.completed !== undefined) {
      updateChecked(location.state.completed, true);
    }
  }, [location.state, updateChecked]);

  useEffect(() => {
    const newChecked = initialPhotoTypes.map((_, index) => !!photos[index]);
    setChecked(newChecked);

    if (newChecked.every(Boolean)) {
      setCompletionModalOpen(true);
    }
  }, [photos]);

  const handlePhotoClick = (index) => {
    setSelectedPhoto(index);
    setOpen(true);
  };

  const handleCapture = () => {
    setOpen(false);
    if (selectedPhoto === 10) {
      
    } else {
      history.push(`/camera/${selectedPhoto.toString()}`);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        if (typeof onSavePhoto === 'function') {
          onSavePhoto(10, base64Image);
          updateChecked(10, true);
          setOpen(false);
        } else {
          console.error('onSavePhoto is not a function');
          
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCameraCapture = () => {
    setOpen(false);
    history.push('/camera/10');
  };

  const handleProcessImages = () => {
    history.push('/process-images');
  };

  const completedPhotosCount = checked.filter(Boolean).length;
  const allPhotosTaken = completedPhotosCount === initialPhotoTypes.length;

  return (
    <Container className="container" style={{ backgroundColor: '#F9FCFF', minHeight: '100vh' }}>
      <Box textAlign="center" mb={3}>
        <img src={logo} alt="Flowsure Logo" style={{ width: '150px', margin: '20px auto' }} />
        <Typography variant="h5" style={{ color: '#261d56' }}>
          Fotos ({completedPhotosCount}/11)
        </Typography>
      </Box>
      <Grid container spacing={3} mt={2}>
        {initialPhotoTypes.map((type, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box 
              onClick={() => handlePhotoClick(index)}
              className={`checklist-box ${checked[index] ? 'checked' : ''}`}
              p={2}
              textAlign="center"
              style={{ cursor: 'pointer', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
            >
              <Typography variant="body1" style={{ color: '#261d56' }}>{type.title}</Typography>
              <img src={photos[index] || type.img} alt={type.title} className="checklist-image" />
            </Box>
          </Grid>
        ))}
      </Grid>
      {allPhotosTaken && (
        <Box mt={4} textAlign="center">
          <Button 
            variant="contained" 
            className="primary-button"
            onClick={handleProcessImages} 
            style={{ width: '80%', maxWidth: '300px' }}
          >
            Procesar Imágenes
          </Button>
        </Box>
      )}
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box 
          bgcolor="white" 
          p={3} 
          style={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {selectedPhoto !== null && (
            <>
              <Typography variant="h6" style={{ color: '#261d56', marginBottom: '15px' }}>
                {initialPhotoTypes[selectedPhoto].title}
              </Typography>
              <Box 
                style={{ 
                  width: '100%', 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  marginBottom: '15px'
                }}
                >
                  <img 
                    src={photos[selectedPhoto] || initialPhotoTypes[selectedPhoto].img} 
                    alt={initialPhotoTypes[selectedPhoto].title} 
                    className="checklist-image" 
                    style={{ 
                      maxWidth: '100%', 
                      height: 'auto', 
                      maxHeight: '30vh'
                    }} 
                  />
                </Box>
                {selectedPhoto === 10 ? (
                  <>
                    <Button 
                      variant="contained" 
                      className="primary-button"
                      onClick={() => document.getElementById('fileInput').click()} 
                      style={{ 
                        marginTop: '20px',
                        width: '80%',
                        maxWidth: '300px'
                      }}
                      startIcon={<FileUploadIcon />}
                    >
                      Subir Documento
                    </Button>
                    <Button 
                      variant="contained" 
                      className="secondary-button"
                      onClick={handleCameraCapture} 
                      style={{ 
                        marginTop: '20px',
                        width: '80%',
                        maxWidth: '300px'
                      }}
                      startIcon={<CameraAltIcon />}
                    >
                      Usar Cámara
                    </Button>
                  </>
                ) : (
                  <>
                    {isAutoDetectionEnabled(selectedPhoto) && (
                      <Box mt={2} display="flex" flexDirection="column" alignItems="center" style={{ marginBottom: '15px' }}>
                        <Speedometer value={0} />
                        <Typography variant="body2" style={{ marginTop: '10px', color: '#261d56', textAlign: 'center' }}>
                          Este medidor indica la precisión de la detección automática. 
                          La fotografía se tomará automáticamente cuando el medidor llegue al 100%.
                        </Typography>
                      </Box>
                    )}
                    <Button 
                      variant="contained" 
                      className="primary-button"
                      onClick={handleCapture} 
                      style={{ 
                        marginTop: '20px',
                        width: '80%',
                        maxWidth: '300px'
                      }}
                    >
                      Capturar Foto
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>
        </Modal>
        <Modal open={completionModalOpen} onClose={() => setCompletionModalOpen(false)}>
          <Box bgcolor="white" p={3} textAlign="center" style={{ margin: 'auto', marginTop: '10%', width: '80%', maxWidth: '400px' }}>
            <Typography variant="h6" style={{ color: '#261d56' }}>Inspección completada</Typography>
            <Typography variant="body1" style={{ color: '#261d56' }}>
              ¡Has completado la inspección! Todas las fotos necesarias han sido tomadas.
            </Typography>
            <Button 
              variant="contained" 
              className="primary-button"
              onClick={() => setCompletionModalOpen(false)} 
              style={{ marginTop: '20px' }}
            >
              Cerrar
            </Button>
          </Box>
        </Modal>
      </Container>
    );
  };
  
  export default PhotoChecklist;