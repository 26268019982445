import React from 'react';
import '../styles/Speedometer.css';

const Speedometer = ({ value }) => {
  const rotation = (value / 100) * 180 - 90;

  return (
    <div className="speedometer">
      <div className="speedometer-scale"></div>
      <div 
        className="speedometer-needle" 
        style={{ transform: `rotate(${rotation}deg)` }}
      ></div>
      <div className="speedometer-value">{Math.round(value)}%</div>
    </div>
  );
};

export default Speedometer;